import { graphql } from 'gatsby'
import React from 'react'

import Article from '../screens/article'

const Research = (props) => {
  const article = props.data.wpReportsAndanalysis
  return <Article {...props} article={article} category="research" />
}

export default Research

export const pageQuery = graphql`
  query ReportAndAnalysisById($slug: String!, $lang: String) {
    wpReportsAndanalysis(
      locale: { locale: { eq: $lang } }
      slug: { eq: $slug }
    ) {
      id
      title
      content
      date(formatString: "MMM DD, YYYY")
      dateGmt
      slug
      status
      excerpt
      nodeType
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
          slug
          description
          id
        }
      }
      acf: acfReportAndAnalysis {
        articleExcerpt
      }
      featuredImage {
        node {
          caption
          sourceUrl

        }
      }
    }
  }
`
